import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import testimonialImage from '../Assets/Images/testimonial.jpg';
import quotationImage from '../Assets/Images/quotation.svg';

import insightsIcon from '../Assets/Images/insights-icon.png';
import alignmentIcon from '../Assets/Images/alignment-icon.png';
import directionIcon from '../Assets/Images/direction-icon.png';

import '../App.scss';

const Results = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();

  const [isLoading, setIsLoading]     = React.useState(true);
  const [isMember, setIsMember]       = React.useState(false);
  const [name, setName]               = React.useState("");
  const [score, setScore]             = React.useState(0);
  const [subScore1, setSubScore1]     = React.useState(0);
  const [subScore2, setSubScore2]     = React.useState(0);
  const [subScore3, setSubScore3]     = React.useState(0);
  const [subScore4, setSubScore4]     = React.useState(0);

  useEffect(() => {
    document.body.style.backgroundColor = "#1F2A44";
    document.getElementById('assessment-title').style.color = "#FBF9F2";
    fetchResults();
  }, []);

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        console.log(data);
				setIsLoading(false);
				setName(data.name);
				setScore(data.score);
				setSubScore1(data.subScore1);
				setSubScore2(data.subScore2);
				setSubScore3(data.subScore3);
				setSubScore4(data.subScore4);
        setIsMember(data.isTeamMember);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  const showTitleText = () => {
    if(score > 70) {
      return <h1>{name}, your church exceeds expectations</h1>
    } else if(score > 45) {
      return <h1>{name}, your church meets expectations</h1>
    } else {
      return <h1>{name}, your church needs improvement</h1>
    }
  }

  const showScoreText = () => {
    if(score > 70) {
      return <div className="result-text">
        Congratulations! It looks like your church is doing a great job at ensuring it is prepared for future evangelism.
      </div>
    } else if(score > 45) {
      return <div className="result-text">
        While you are on a journey to ensuring your church is prepared for future evangelism, there's room for reaching out to more of the unchurched in your community.
      </div>
    } else {
      return <div className="result-text">
        It looks like your church needs to work towards reaching out to the unchurched in your community.
      </div>
    }
  }

  const showInvite = () => {
    if(isMember) {
      return null;
    } else {
      return <div className="invite-container">

        <h3>Invite your team to take this test</h3>
        <p>Compare your results with those of your team to gain new insights into<br/>
        your church’s outreach strategy and align around actionable goals.</p>

        <div className="invite-reasons">

          <div className="reason">
            <img src={insightsIcon} /><br/>
            <div className="height30"></div>
            <span>Analyze</span><br/>
            Discover areas of opportunity for church outreach
          </div>

          <div className="reason">
            <img src={alignmentIcon} /><br/>
            <div className="height30"></div>
            <span>Align</span><br/>
            Align around a plan for future evangelism as a team
          </div>

          <div className="reason">
            <img src={directionIcon} /><br/>
            <div className="height30"></div>
            <span>Advance</span><br/>
            Further your church's mission for evangelism and discipleship
          </div>

        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link>
        </div>

      </div>
    }
  }

  const showTeamMember = () => {
    if(isMember) {
      return <div className="team-container">
        <h3>Thank you for taking this test!</h3>
        Please get in touch with your leader for the next step.<br/><br/>
      </div>
    } else {
      return null;
    }
  }

  const getLeftMargin = () => {
    if(window.innerWidth < 800 && score > 95) {
      return { left: 90 + '%'}
    } else if(window.innerWidth < 800 && score < 5) {
      return { left: 10 + '%'}
    } else {
      return { left: score + '%'};
    }
  }

  const showLegacyInvite = () => {
    if(isMember) {
      return null;
    } else {
      return <div className="invite-legacy-container">
        Invite your team to take this test along with you and begin to align around an actionable strategy for church outreach!
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link>
        </div>
      </div>
    }
  }

  const showSchedule = () => {
    if(isMember) {
      return null;
    } else {
      return <div className="schedule-container">
        Schedule a call with Carey to debrief the results of your test and ensure your
        church is prepared with next steps for future evangelism.
        <div className="cta-btn">
          <a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
            <Button>TALK WITH CAREY</Button>
          </a>
        </div>
      </div>
    }
  }

  if (isLoading) {
		return <div className="container results loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}

  return (
    <div>
      <div className="container results">
        
        <div className="details-container">
          
        {showTitleText()}

        <div className="progress-bar">
          <div className="si_ass_result"> 
            <div className="si_ass_progress">
              <div className="progress-line" style={{ left: score + '%'}}></div>
              <span className="msi_score" style={getLeftMargin()}>{score}%</span>
            </div>
            <div className="pervalue">
              <h4 className="nimp" style={{ minWidth: '45%' }}></h4> 
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
            </div> 
            <div className="si_pro_header">
              <h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br/>improvement</h4> 
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br/>expectations</h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br/>expectations</h4>
            </div> 
          </div>
        </div>

        {showScoreText()}
        

        <div className="result-sections">

          <div className="score-section">            
            <div className="score-txt">{subScore1}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore1} /></div>
            BELIEFS
          </div>

          <div className="score-section">
            <div className="score-txt">{subScore2}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore2} /></div>
            STRATEGY
          </div>

          <div className="score-section">
            <div className="score-txt">{subScore3}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore3} /></div>
            RELEVANCE
          </div>

          <div className="score-section">
            <div className="score-txt">{subScore4}%</div>
            <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore4} /></div>
            PROCESS
          </div>

        </div>
  
      </div>
        
    </div>
      
    {showInvite()}
    {showTeamMember()}

    <div className="testimonal-container">

      <div className="testimonial">

        <div className="testi">
          <span className="quotation-mark">“</span>Doing this assessment changed the way our team approached outreach. It<br/>
          helped us evaluate our strategy for evangelism and discipleship and<br/>
          equipped us with tangible next steps to reach out to more of the<br/>
          unchurched and de-churched in our community.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
        </div>

        <div className="avatar">
          <img src={testimonialImage} />
        </div>
        
        <div className="designation">
          Sam Davis<br/>
          <span>Senior Pastor, New Life Church</span>
        </div>

      </div>

    </div>
      
    {showLegacyInvite()}
    {/* {showSchedule()} */}

    </div>
  );
  
}
export default Results;
import React from 'react';
import { Fragment } from 'react';
import { Link } from "react-router-dom";

import Logo from '../../Assets/Images/logo.png';
import churchOutreachLogo from '../../Assets/Images/logo-outreach.png';
import BleatLogo from '../../Assets/Images/logo-bleat.svg';
import BleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';

const Header = props => {
  return <Fragment>
    <header>
      <Link to="/start" className="logo"><img src={Logo} /></Link>
      <div className="assessment-title" id="assessment-title"><img src={churchOutreachLogo} /></div>
      <div className="build-by">
        <img src={BleatLogo} class="desktop" alt="Bleat Logo"/>
        <img src={BleatLogoMobile} class="mobile" alt="Bleat Logo" />
      </div>
    </header>
  </Fragment>
}

export default Header;
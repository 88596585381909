import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const jobTitleValues = [
  {value: "Other"},
  {value: "Administrative Pastor"},
  {value: "Campus Pastor"},
  {value: "Children’s Pastor"},
  {value: "College Pastor"},
  {value: "Community Pastor"},
  {value: "Connections Pastor"},
  {value: "Creative Arts Pastor"},
  {value: "Discipleship Pastor"},
  {value: "Executive Pastor"},
  {value: "Family Pastor"},
  {value: "High School Pastor"},
  {value: "Leadership Development Pastor"},
  {value: "Middle School Pastor"},
  {value: "Missions Pastor"},
  {value: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor"},
  {value: "Small Group Pastor"},
  {value: "Stewardship Pastor"},
  {value: "Student Pastor"},
  {value: "Teacher Pastor"},
  {value: "Worship Pastor"},
  {value: "Administrative Assistant"},  
  {value: "AV Director"},
  {value: "Bookkeeper"},
  {value: "Chief Financial Officer"},
  {value: "Children’s Ministry Coordinator"},
  {value: "Communications Coordinator"},
  {value: "Communications Director"},
  {value: "Communications Project Manager"},
  {value: "Content Writer"},
  {value: "Digital Media Analyst"},
  {value: "Events Coordinator"},
  {value: "Facility Manager"},
  {value: "Graphic Designer"},
  {value: "Groups Director"},
  {value: "Guest Services Director"},
  {value: "Human Resources Coordinator"},
  {value: "IT Director"},
  {value: "Mission and Outreach Coordinator"},
  {value: "Office Manager"},
  {value: "Online Community Manager"},
  {value: "Pastoral Care Minister"},
  {value: "Preschool Director"},
  {value: "Production Coordinator"},
  {value: "Social Media Manager"},
  {value: "Video Producer"},
  {value: "Volunteer Coordinator"},
  {value: "Web Developer"},
  {value: "Worship Leader"}
];

const Details = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]       = React.useState(false);
  const [isLoading, setIsLoading]       = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isMember, setIsMember]       = React.useState(true);

  const [firstName, setFirstName]     = React.useState("");
  const [lastName, setLastName]       = React.useState("");
  const [email, setEmail]             = React.useState("");
  const [jobTitle, setJobTitle]       = React.useState("none");
  const [jobTitleMember, setJobTitleMember]       = React.useState("");
  const [churchName, setChurchName]   = React.useState("");
  const [zipCode, setZipCode]         = React.useState("");
  const [firstNameError, setFirstNameError]       = React.useState(false);
  const [lastNameError, setLastNameError]         = React.useState(false);
  const [emailError, setEmailError]               = React.useState(false);
  const [jobTitleError, setJobTitleError]         = React.useState(false);
  const [churchNameError, setChurchNameError]     = React.useState(false);
  const [zipCodeError, setZipCodeError]           = React.useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#152036";
    console.log("Do we run again");
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
  }, []);

  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setJobTitle("none");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  const updateJobTitle = (event) => {
    console.log(event.target.value);
    setJobTitle(event.target.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if(isMember) {
      console.log(firstName);
      console.log(jobTitle);
      console.log(jobTitleMember);
      if(firstName != "" && lastName != "") {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if(firstName != "" && lastName != "" && email != "" && jobTitle != "none" && churchName != "" && zipCode != "") {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(firstName == "") {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(lastName == "") {
      setLastNameError(true);
    }
    if(email == "") {
      setEmailError(true);
    }
    if(jobTitle == "none") {
      setJobTitleError(true);
    }
    if(churchName == "") {
      setChurchNameError(true);
    }
    if(zipCode == "") {
      setZipCodeError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        jobTitle,
        churchName,
        zipCode
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");


    if(isMember && firstName != "" && lastName != "" && jobTitle != "none") {
      submitToApi();
    } else if(!isMember && firstName != "" && lastName != "" && email != "" && jobTitle != "none" && churchName != "" && zipCode != "") {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{'color': '#fff'}} size={23} /></div>
    }
    return "SHOW ME MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please confirm a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please select an option.</div>
      }
    }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your church name.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
  }

  const removeHintText = (field) => {
    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
  }

  if (isLoading) {
		return <div className="container details loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        <h1>You’re all done!</h1>
        <div style={{ height: '10px' }}></div>
        <p>Before we get to your report, please confirm<br/>your details so that we can email you a copy.</p>

        <div className="form">

          <div className="form-detail">
            <p>First name<span>*</span></p>
            <TextField hiddenLabel placeholder="Simon" value={firstName} onFocus={() => removeHintText("firstName")} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p>Last name<span>*</span></p>
            <TextField hiddenLabel placeholder="Peter" value={lastName} onFocus={() => removeHintText("lastName")}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>

          <div className="form-detail">
            <p>Email<span>*</span></p>
            <TextField hiddenLabel placeholder="simon@rockchurch.com" disabled={isMember} value={email} onFocus={() => removeHintText("email")} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          <div className="form-detail">
            <p>Job title<span>*</span></p>
            <TextField select hiddenLabel value={jobTitle} onFocus={() => removeHintText("jobTitle")} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            {showHintText("jobTitle")}
          </div>

          {
            !isMember ? (
              <div className="form-detail">
                <p>Church name<span>*</span></p>
                <TextField hiddenLabel placeholder="Rock Church" value={churchName} onFocus={() => removeHintText("churchName")} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          }
          
          {
            !isMember ? (
              <div className="form-detail">
                <p>ZIP code<span>*</span></p>
                <TextField hiddenLabel placeholder="11618" value={zipCode} onFocus={() => removeHintText("zipCode")} onChange={(e) => {updateZipCode(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("zipCode")}
              </div>
            ) : null
          }

          <div className="cta-btn">
            <Button onClick={handleSubmit} disabled={isSaving}>
              {showButton()}
            </Button>
          </div>

        </div>
        

      </div>
      
    </div>
  );
  
}
export default Details;